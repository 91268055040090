body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.bg-img {
  /* background-image: url("images/light-grey-terrazo.png") ; */
  background-color: d3d3d3;
}

.rec-bg-color {
  background-color: 858585;
  /* border-radius: 20px; */
  border: 2px solid black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav a.active {
  /* background-color: turquoise; */
  font-weight: bolder;

}

.float-left{
  float: left;
}

.float-right{
  float: right;
}

.no-list-decoration {
  list-style-type: none;
}

.center-pos {
  text-align: center;
}

.justify-txt {
  text-align: justify;
}

.tranlusent {
  opacity: 1;
}

.big-letter {
  font-size: 50px;
}

.med-letter {
  font-size: 35px;
}

.footer-area {
  height: 60px;
  background-color: #2d2d2d;
}

.footer-font {
  color: white;
  text-align: center;
  /* justify-content: center; */

  /* To bring text to vertical center */
  /* align-items: center;   */
}
